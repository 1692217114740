import React from 'react';
import kitty from './kitty@3x.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <img src={kitty} className="kitty" alt="kitty" />
    </div>
  );
}

export default App;
